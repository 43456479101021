import React from "react";
import "./hero.scss";
import $ from "jquery";
import {
  TweenLite,
  TimelineMax,
  TweenMax as TM,
  Linear,
  Power3,
  Power2,
  Bounce,
  Elastic
} from "gsap";
import { isBrowser, isDesktop, isMobile, isTablet } from "react-device-detect";
import arrow from "../../images/header/arrow.svg";
import arrowMove from "../../images/hero/arrow_move.svg";
import phoneImg from "../../images/Phone/iPhoneXS - Space Gray@2x.png";
import appVideo from "../../videos/prspr_video.mp4";
import animateScrollTo from "animated-scroll-to";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playedOnce: false,
      earlyAccessSectionAnimation: false,
      muteVideo: true
    };
  }
  componentDidMount() {
    TM.fromTo(
      this.phone,
      1,
      { opacity: 0, transform: "translateY(5%)" },
      {
        opacity: 1,
        delay: 0.5,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
    TM.fromTo(
      this.video,
      1,
      { opacity: 0, transform: "translateY(5%)" },
      {
        opacity: 1,
        delay: 0.5,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
    TM.fromTo(
      this.text1,
      1,
      { opacity: 0, transform: "translateY(20%)" },
      {
        opacity: 1,
        delay: 0.2,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
    TM.fromTo(
      this.text2,
      1,
      { opacity: 0, transform: "translateY(20%)" },
      {
        opacity: 1,
        delay: 0.4,
        transform: "translateY(0%)",
        ease: Power2.easeOut
      }
    );
    this.playMovingTextAnimation();
    if (isMobile) {
      if (this.arrowMove) {
        TM.to(this.arrowMove, 0.5, {
          transform: "scale(1.2)",
          repeat: -1,
          yoyo: true
        });
      }
    }
  }

  scrollToVideo = () => {
    var height = document.getElementById("videoSection").offsetTop + 40;
    animateScrollTo(height);
  };

  startTextLineMovingAnimation = (wrapper, list, listItem, direction) => {
    var $tickerWrapper = $(wrapper);
    var $list = $tickerWrapper.find(list);
    var $clonedList = $list.clone();
    var listWidth = isDesktop ? 400 : 200;
    $list.find(listItem).each(function(i) {
      listWidth += $(this, i)[0].offsetWidth;
    });

    var endPos = $tickerWrapper.width() - listWidth;

    $list.add($clonedList).css({
      width: listWidth + "px"
    });

    $clonedList.addClass("cloned").appendTo($tickerWrapper);

    //TimelineMax
    var infinite = new TimelineMax({
      force3D: true,
      repeat: -1,
      paused: false
    });
    var time = 15;

    infinite.fromTo(
      $list,
      time,
      { x: direction == "left" ? 0 : -listWidth },
      { x: direction == "left" ? -listWidth : 0, ease: Linear.easeNone },
      0
    );
    infinite.fromTo(
      $clonedList,
      time,
      { x: direction == "left" ? listWidth : 0 },
      { x: direction == "left" ? 0 : listWidth, ease: Linear.easeNone },
      0
    );
    if (direction == "left") {
      infinite.set($list, { x: listWidth });
    } else {
      infinite.set($clonedList, { x: -listWidth });
    }
    infinite.to(
      $clonedList,
      time,
      { x: direction == "left" ? -listWidth : 0, ease: Linear.easeNone },
      time
    );
    infinite.to(
      $list,
      time,
      { x: direction == "left" ? 0 : listWidth, ease: Linear.easeNone },
      time
    );

    //Pause/Play

    $tickerWrapper
      .on("mouseenter", function() {
        TM.to(infinite, 2, {
          timeScale: 0.3
        });
      })
      .on("mouseleave", function() {
        TM.to(infinite, 2, {
          timeScale: 1
        });
      });
  };

  playMovingTextAnimation = () => {
    setTimeout(() => {
      this.startTextLineMovingAnimation(
        "#earlyAccessRollingRow1",
        ".hero__cont_movingTextCont_rollingRowWrapper_row",
        ".hero__cont_movingTextCont_rollingRowWrapper_row_text",
        "left"
      );

      this.startTextLineMovingAnimation(
        "#earlyAccessRollingRow2",
        ".hero__cont_movingTextCont_rollingRowWrapper2_row",
        ".hero__cont_movingTextCont_rollingRowWrapper2_row_text",
        "right"
      );

      this.startTextLineMovingAnimation(
        "#earlyAccessRollingRow3",
        ".hero__cont_movingTextCont_rollingRowWrapper3_row",
        ".hero__cont_movingTextCont_rollingRowWrapper3_row_text",
        "left"
      );

      this.startTextLineMovingAnimation(
        "#earlyAccessRollingRow4",
        ".hero__cont_movingTextCont_rollingRowWrapper4_row",
        ".hero__cont_movingTextCont_rollingRowWrapper4_row_text",
        "right"
      );

      this.startTextLineMovingAnimation(
        "#earlyAccessRollingRow5",
        ".hero__cont_movingTextCont_rollingRowWrapper5_row",
        ".hero__cont_movingTextCont_rollingRowWrapper5_row_text",
        "left"
      );

      this.startTextLineMovingAnimation(
        "#earlyAccessRollingRow6",
        ".hero__cont_movingTextCont_rollingRowWrapper6_row",
        ".hero__cont_movingTextCont_rollingRowWrapper6_row_text",
        "right"
      );

      this.setState({
        earlyAccessSectionAnimation: true
      });
    }, 300);
  };

  render() {
    return (
      <div className="hero">
        <div className="hero__cont">
          <div className="hero__cont_movingTextCont">
            <div
              className="hero__cont_movingTextCont_rollingRowWrapper"
              id="earlyAccessRollingRow1"
              ref={(ref) => (this.earlyAccesRollingRow1 = ref)}
            >
              <div className="hero__cont_movingTextCont_rollingRowWrapper_row">
                <div className="hero__cont_movingTextCont_rollingRowWrapper_row_text">
                  Personal
                </div>
                <div className="hero__cont_movingTextCont_rollingRowWrapper_row_text">
                  Finance$$Money
                </div>
              </div>
            </div>
            <div
              className="hero__cont_movingTextCont_rollingRowWrapper2"
              id="earlyAccessRollingRow2"
              ref={(ref) => (this.earlyAccesRollingRow2 = ref)}
            >
              <div className="hero__cont_movingTextCont_rollingRowWrapper2_row">
                <div className="hero__cont_movingTextCont_rollingRowWrapper2_row_text">
                  Tips$$Stocks$$401k$$DAOs$$
                </div>
              </div>
            </div>
            <div
              className="hero__cont_movingTextCont_rollingRowWrapper3"
              id="earlyAccessRollingRow3"
              ref={(ref) => (this.earlyAccesRollingRow3 = ref)}
            >
              <div className="hero__cont_movingTextCont_rollingRowWrapper3_row">
                <div className="hero__cont_movingTextCont_rollingRowWrapper3_row_text">
                  Business
                </div>
                <div className="hero__cont_movingTextCont_rollingRowWrapper3_row_text">
                  news$$Market
                </div>
              </div>
            </div>
            <div
              className="hero__cont_movingTextCont_rollingRowWrapper4"
              id="earlyAccessRollingRow4"
              ref={(ref) => (this.earlyAccesRollingRow4 = ref)}
            >
              <div className="hero__cont_movingTextCont_rollingRowWrapper4_row">
                <div className="hero__cont_movingTextCont_rollingRowWrapper4_row_text">
                  Trends$$DeFi$$Taxes$$Crypto$$
                </div>
              </div>
            </div>
            <div
              className="hero__cont_movingTextCont_rollingRowWrapper5"
              id="earlyAccessRollingRow5"
              ref={(ref) => (this.earlyAccesRollingRow5 = ref)}
            >
              <div className="hero__cont_movingTextCont_rollingRowWrapper5_row">
                <div className="hero__cont_movingTextCont_rollingRowWrapper5_row_text">
                  Bitcoin$$Investing$$Etherum
                </div>
              </div>
            </div>
            <div
              className="hero__cont_movingTextCont_rollingRowWrapper6"
              id="earlyAccessRollingRow6"
              ref={(ref) => (this.earlyAccesRollingRow6 = ref)}
            >
              <div className="hero__cont_movingTextCont_rollingRowWrapper6_row">
                <div className="hero__cont_movingTextCont_rollingRowWrapper6_row_text">
                  $$NFTs$$Budgeting$$Market
                </div>
              </div>
            </div>
          </div>
          <div className="hero__cont_buttonsCont">
            <div className="hero__cont_buttonsCont_actions">
              <div className="hero__cont_buttonsCont_actions_button vrlps-trigger">
                <span className="hero__cont_buttonsCont_actions_button_p">
                  <strong>Join</strong> waitlist now
                </span>
                <img src={arrow} />
              </div>
              <div className="hero__cont_buttonsCont_actions_link">
                <a
                  href="https://docs.google.com/forms/d/1HobooF5U_qvWtTfJkSJ7KkQyv7xJx6_x-xcKu5ZhaCI/viewform?edit_requested=true"
                  target="_blank"
                >
                  <p
                    className={
                      "hero__cont_buttonsCont_actions_link_p " +
                      (this.props.activeSection == 0
                        ? "hero__cont_buttonsCont_actions_link_p_active"
                        : "hero__cont_buttonsCont_actions_link_p")
                    }
                  >
                    <strong>Become</strong> a Creator
                  </p>
                </a>
              </div>
            </div>
            <div
              ref={(ref) => (this.arrowMove = ref)}
              className="hero__cont_buttonsCont_arrowBtn"
              onClick={this.scrollToVideo}
            >
              <img src={arrowMove} />
            </div>
          </div>
          <div
            id="videoSection"
            className="hero__cont_appPreview"
            onClick={() => this.setState({ muteVideo: !this.state.muteVideo })}
          >
            <video
              ref={(ref) => (this.video = ref)}
              autoPlay
              muted={this.state.muteVideo}
              loop
              playsInline
              className="hero__cont_appPreview_video"
            >
              <source src={appVideo} type="video/mp4" />
            </video>
            <img
              ref={(ref) => (this.phone = ref)}
              className="hero__cont_appPreview_phoneImg"
              src={phoneImg}
            />
          </div>
          <div className="hero__cont_textCont">
            <h2 ref={(ref) => (this.text1 = ref)}>
              Best trending
              <br />
              personal finance videos
            </h2>
            <p ref={(ref) => (this.text2 = ref)}>
              Financial literacy can be taught socially and as entertainment. We
              match people to the best financial creators and services available
              in market.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Hero;
