import React from "react";
import logo from "../../images/Logo/logo.svg";
import arrow from "../../images/header/arrow.svg";
import "./header.scss";
import { OutboundLink } from "gatsby-plugin-google-analytics";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <header className="header">
        <div className="header__cont">
          <div className="header__cont__firstCol">
            <OutboundLink href="/" className="header__cont_a">
              <img className="header__cont_a_logo" src={logo}></img>
            </OutboundLink>
          </div>
          <div className="header__cont__secondCol">
            <div className="header__cont__secondCol_link">
              <a
                href="https://docs.google.com/forms/d/1HobooF5U_qvWtTfJkSJ7KkQyv7xJx6_x-xcKu5ZhaCI/viewform?edit_requested=true"
                target="_blank"
              >
                <p
                  className={
                    "header__cont__secondCol_link_p " +
                    (this.props.activeSection == 0
                      ? "header__cont__secondCol_link_p_active"
                      : "header__cont__secondCol_link_p")
                  }
                >
                  <strong>Become</strong> a Creator
                </p>
              </a>
            </div>
            <div className="header__cont__secondCol_button vrlps-trigger">
              <span className="header__cont__secondCol_button_p">
                <strong>Join</strong> waitlist now
              </span>
              <img src={arrow} />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
